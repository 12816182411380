<template>
  <div>
    <GlobalForm
      class="global-form"
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="100"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        class="el_from"
        label-width="80px"
        label="旧机情况"
        slot="operationSelect"
        style="margin:0"
      >
        <el-select v-model="copyType" placeholder="请选择" clearable filterable>
          <el-option
            v-for="item in shanpsSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label-width="80px"
        label="渠道选择"
        slot="matchChannelId"
        style="margin: 0"
      >
        <el-select
          v-model="matchChannelId"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in channelSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="询价商家"
        slot="merchId"
        style="margin: 0"
        label-width="80px"
      >
        <el-select
          v-model="companyIdcopy"
          placeholder="请输入或选择商家"
          filterable
          clearable
          @change="handleChangeInquiry"
        >
          <el-option
            v-for="item in merchSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="询价门店"
        slot="stockStoreId"
        style="margin: 0"
        label-width="80px"
      >
        <el-select
          v-model="storeId"
          placeholder="请先选择询价商家"
          filterable
          clearable
          :disabled="isHasCompany"
        >
          <el-option
            v-for="item in storeList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label-width="95px"
        label="预估价区间"
        slot="estimatedrange"
        style="margin:0"
      >
        <el-select v-model="esPrice" placeholder="请选择" multiple clearable filterable>
          <el-option
            v-for="item in estimatedList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label="审单人"
        slot="merchplope"
        style="margin: 0"
        label-width="65px"
      >
        <el-select v-model="estimateAdminId" placeholder="请选择" filterable clearable>
          <el-option
            v-for="item in actionAdminList"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <template>
        <el-button
          style="margin-left: 20px;"
          icon="el-icon-download"
          type="success"
          size="small"
          :key="80"
          @click="exportExcel"
          >导出Excel</el-button
        >
      </template> -->
    </GlobalForm>
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
import { MessageBox } from "element-ui";
export default {
  data() {
    return {
      esPrice: "", //预估价区间
      isHasCompany: true,
      matchChannelId: "",
      companyIdcopy: "",
      storeId: "",
      estimateAdminId: "",
      channelSelectList: [],
      merchSelectList: [],
      actionAdminList: [],
      storeList: [],
      queryDate: "",
      serParanms:{
        startTime: '',
          endTime: '',
      },
      initData: {
        entryTime: [
          moment()
            .day(moment().day())
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      copyType: "",
      shanpsSelectList: [
        {
          name: "靓机",
          id: "Nice",
        },
        {
          name: "小花",
          id: "Little_Snow",
        },
        {
          name: "大花",
          id: "Big_Snow",
        },
        {
          name: "外爆",
          id: "Out_Burst",
        },
        {
          name: "内爆",
          id: "Inner_Burst",
        },
      ],
      estimatedList: [
        {
          name: "0-50",
          id: "0-50",
        },
        {
          name: "50-100",
          id: "50-100",
        },
        {
          name: "100-500",
          id: "100-500",
        },
        {
          name: "500-1000",
          id: "500-1000",
        },
        {
          name: "1500-2000",
          id: "1500-2000",
        },
        {
          name: "2000-2500",
          id: "2000-2500",
        },
        {
          name: "2500-3000",
          id: "2500-3000",
        },
        {
          name: "3000-4000",
          id: "3000-4000",
        },
        {
          name: "4000-5000",
          id: "4000-5000",
        },
        {
          name: "5000-8000",
          id: "5000-8000",
        },
        {
          name: "8000以上",
          id: "8000-",
        },
      ],
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      formItemList: [
        {
          lableWidth: "80px",
          key: "orderNo",
          type: "input",
          labelName: "订单编号",
          placeholder: "请输入订单编号",
        },
        {
          lableWidth: "80px",
          key: "imei",
          type: "input",
          labelName: "旧机串号",
          placeholder: "请输入旧机串号",
        },
        {
          lableWidth: "80px",
          key: "staffName",
          type: "input",
          labelName: "下单店员",
          placeholder: "请输入下单店员",
        },
        {
          lableWidth: "80px",
          key: "phoneName",
          type: "input",
          labelName: "回收商品",
          placeholder: "请输入商品名称",
        },
        { slotName: "operationSelect" },
        { slotName: "matchChannelId" },
        { slotName: "merchId" },
        { slotName: "stockStoreId" },
        { slotName: "merchplope" },
        { slotName: "estimatedrange" },
        {
          lableWidth: "55px",
          key: "entryTime",
          type: "pickerOptions",
          labelName: "时间",
          placeholder: "请选择时间",
          valueFormat: "timestamp",
          clearable: true,
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
        { slotName: "AddSlot" },
      ],
    };
  },
  created() {
    this.getMatchChannels();
    this.getSelectList();
    this.getadminSelectList();
  },
  computed: {
    //审单状态
    stanType() {
      return this.$store.state.tagsView.stanType;
    },
  },
  watch: {
    companyIdcopy(newVal) {
      if (newVal != "") {
        this.isHasCompany = false;
      } else {
        this.storeId = "";
        this.isHasCompany = true;
      }
    },
  },
  methods: {
    //选择所属商家
    handleChangeInquiry(val) {
      console.log(val);
      this.companyIdcopy = val;
      if (val != "") {
        this.storeId = "";
        this.getStoreSelectList();
      }
    },
    // 获取门店列表
    getStoreSelectList() {
      _api.getStoreSelectList({ companyId: this.companyIdcopy }).then((res) => {
        console.log(res);
        if (res.code === 1) {
          this.storeList = res.data;
        }
      });
    },
    //审单人列表
    getadminSelectList() {
      _api.StaffManageList({ pageNum: 1, pageSize: 99999 }).then((res) => {
        this.actionAdminList = res.data.records;
      });
    },
    // 所属商家下拉
    getSelectList() {
      _api.getSelectList().then((res) => {
        if (res.code === 1) {
          this.merchSelectList = res.data;
        }
      });
    },
    // 获取渠道
    getMatchChannels() {
      _api.getMatchChannelSelect().then((res) => {
        if (res.code === 1) {
          this.channelSelectList = res.data;
        }
      });
    },
    handleConfirm(data,cd) {
      console.log(data, this.esPrice);
      let SeachParams = {};
      if (data) {
        let startTime = "";
        let endTime = "";
        if (data.entryTime === null) {
          startTime = "";
          endTime = "";
        } else {
          startTime = moment(data.entryTime[0]).format("x");
          endTime = moment(data.entryTime[1]).format("x");
        }
        SeachParams = {
          startTime: startTime,
          endTime: endTime,
          companyIdcopy: this.companyIdcopy,
          estimateAdminId: this.estimateAdminId,
          healthCode: this.copyType,
          imei:data.imei,
          matchId: this.matchChannelId,
          orderNo:data.orderNo,
          phoneName: data.phoneName,
          prePriceRegion: this.esPrice||[],
          staffName: data.staffName,
          storeId: this.storeId,
          pageNum: 1,
          pageSize: 10,
          disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
        };
        this.serParanms=SeachParams
      }
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
     // 导出订单方法
     exportExcel(e) {
      if(!this.serParanms.startTime){
        let startTime = moment() .day(moment().day() - 6).format("YYYY-MM-DD 00:00:00"); // 当前时间往前推15天的时间
        let endTime = moment().format("YYYY-MM-DD 23:59:59");
        this.serParanms.startTime = Date.parse(startTime);
        this.serParanms.endTime = Date.parse(endTime);
      }
      _api.standExcel({estimateState:this.stanType,...this.serParanms}).then((res) => {
        const content = res;
        const blob = new Blob([content]);
        let reader = new FileReader(); // 创建读取文件对象
        reader.addEventListener("loadend", () => {
          try {
            let data = JSON.parse(reader.result);
            if (data.code === 0) {
              MessageBox.alert(data.msg, "错误提示", {
                type: "error",
              });
            }
          } catch (err) {
            const fileName = `人工审单列表.xlsx`;
            if ("download" in document.createElement("a")) {
              // 非IE下载
              const elink = document.createElement("a");
              elink.download = fileName;
              elink.style.display = "none";
              elink.href = URL.createObjectURL(blob);
              document.body.appendChild(elink);
              elink.click();
              URL.revokeObjectURL(elink.href); // 释放URL 对象
              document.body.removeChild(elink);
            } else {
              // IE10+下载
              navigator.msSaveBlob(blob, fileName);
            }
          }
        });
        reader.readAsText(blob, "utf-8");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.global-form {
  /deep/.el-input__inner {
    width: 160px;
  }
}

/deep/.el-form--inline {
  /deep/.el-form-item {
    margin-right: 0px !important;
  }
}

.tip {
  color: #ff687b;
}

.time-select {
  display: flex;
  margin-left: 20px;

  .btn {
    box-shadow: 2px 1px 10px 0px rgba(63, 44, 245, 0.2);
    margin: 0px 10px 0px 0;
    text-align: center;
    cursor: pointer;
  }

  .btnType-after2 {
    // width: 33%;
    width: 80px;
    min-width: max-content;
    height: 30px;
    background: #ffffff;
    color: #0981ff;
    line-height: 30px;
    border-radius: 20px;
  }

  .btnType2 {
    width: 80px;
    min-width: max-content;
    height: 30px;
    background: #0981ff;
    color: white;
    line-height: 30px;
    border-radius: 20px;
  }
}

.andleShowbox {
  border: 1px solid #0981ff;
  width: 200px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;

  .andleShowstyle {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #0981ff;
    cursor: pointer;
    user-select: none;
  }

  .andleShowcolor {
    background-color: #0981ff;
    color: #fff;
  }
}
</style>
